<template>
  <div class="home">
    <!-- 曼扉心理 -->
    <div class="header">
      <Head />
    </div>
    <div class="centerBox">
      <router-view></router-view>
    </div>
    <Bottom />
  </div>
</template>

<script>
// @ is an alias to /src
import Head from '@/components/head/Head.vue';
import Bottom from '@/components/bottom/Bottom.vue'
export default {
  name: 'Home',
  components: {
    Head, Bottom
  },
  data() {
    return {
      nowPath: ''
    }
  },
  methods: {
    routeChange() {
      console.log('发生改变')
    }
  },
  watch: {
    $route: "routeChange"
  },

  created() {
    this.nowPath = this.$route.path;
    let userAgentInfo = navigator.userAgent;
    let mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

    let mobile_flag = false;

    //根据userAgent判断是否是手机
    for (let v = 0; v < mobileAgents.length; v++) {
      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
        mobile_flag = true;
        break;
      }
    }
    this.$store.commit('changeIsPhone', mobile_flag)
    // console.log(mobile_flag);
  }
}
</script>
<style scoped lang="scss">
.home {

  // height: 100vh;
  .header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 5;
  }

  // .centerBox{
  //   min-height: 1000px;
  // }
}</style>