
<template>
    <!-- 顶部 -->
    <!-- <div> -->
    <div class="head">
        <!-- 电脑版header -->
        <div class="pc main">
            <div class="logo">
                <img src="../../assets/images/service/gauge/reportLogo.png" alt="">
            </div>
            <div class="menu">
                <ul>
                    <li :class="path === '/index' ? 'active' : ''" @click="handleSelect('/index')" @mousemove="leave">首页
                    </li>

                    <li @mousemove="inUniversal2" @click="handleSelect('/service/index')"
                        :class="path.indexOf('service') !== -1 ? 'active' : ''">
                        心理服务<i class="el-icon-arrow-down"></i></li>

                    <li @click="handleSelect('/test/gauge')" :class="path.indexOf('test') !== -1 ? 'active' : ''"
                        @mousemove="leave">心理测评</li>

                    <li @click="handleSelect('/enterprise/index')"
                        :class="path.indexOf('enterprise') !== -1 ? 'active' : ''" @mousemove="leave">企业心理服务</li>

                    <li :class="path === '/dcmf' ? 'active' : ''" @click="handleSelect('/dcmf')" @mousemove="leave">了解我们
                    </li>

                    <li @mousemove="inUniversal" @click="handleSelect('/universal')"
                        :class="path.indexOf('universal') !== -1 ? 'active' : ''">心理科普<i class="el-icon-arrow-down"></i>
                    </li>

                    <li @click="handleSelect('/news')" :class="path.indexOf('news') !== -1 ? 'active' : ''"
                        @mousemove="leave">新闻</li>



                </ul>
            </div>
            <div class="secondMenu" @mousemove="inUniversal" v-show="universalShow" @mouseout="leave">
                <div class="item" v-for="item in universalList" :key="item.id">
                    <p class="title">{{ item.name }}</p>
                    <div class="list">
                        <ul>
                            <li @click="toArticleDetail(ele.id, item.id)" v-for="ele in item.list1" :key="ele.id">
                                <span>{{ ele.name }}</span>
                            </li>
                        </ul>
                        <ul v-if="item.list2">
                            <li @click="toArticleDetail(ele2.id, item.id)" v-for="ele2 in item.list2" :key="ele2.id">
                                <span>{{ ele2.name }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="secondMenu secondMenu2" v-show="serviceShow" @mousemove="inUniversal2" @mouseout="leave">
                <div class="item" v-for="item in serviceList" :key="item.id">
                    <div class="list">
                        <ul>
                            <li v-for="ele in item.children" :key="ele.id" @click="toCentre(ele.id)">
                                <span>{{ ele.title }}</span>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
        <div class="main phone">
            <img src="../../assets/images/service/gauge/reportLogo.png" alt="">
            <span>{{ activeName }}</span>
            <i class="el-icon-s-fold" @click="openDraw"></i>
        </div>
        <PhoneMenu ref="child"></PhoneMenu>
    </div>
    <!-- </div> -->
</template>

<script>
import PhoneMenu from './PhoneMenu.vue';
export default {
    components: { PhoneMenu },
    data() {
        return {
            menuList: [
            { id: 1, title: '首页', path: '/index' },
                {
                    id: 5, title: '心理服务', path: '/service/index', show: false, children: [

                        // { id: 10, title: '心理测试', path: '/index' },
                        { id: 1, title: '成瘾中心', path: '/dcmf' },
                        { id: 2, title: '睡眠中心', path: '/news' },
                        { id: 3, title: '关系中心—亲密关系单元', path: '/universal' },
                        { id: 4, title: '关系中心—亲子沟通中心', path: '/service/index' },
                        { id: 5, title: '关系中心—人际关系中心', path: '/enterprise/index' },
                        { id: 6, title: '个人成长中心', path: '/news' },
                        { id: 7, title: '学习困难中心', path: '/news' },
                        { id: 8, title: '情绪障碍中心', path: '/news' },
                        { id: 9, title: '认知障碍中心', path: '/news' },
                    ]
                },
                { id: 7, title: '心理测评', path: '/test/gauge' },
                { id: 6, title: '企业心理服务', path: '/enterprise/index' },
                { id: 2, title: '了解我们', path: '/dcmf' },

                {
                    id: 4, title: '心理科普', path: '/universal', show: false, children: [
                        { id: 1, name: '心理健康', show: false, list1: [{ id: 47, name: '什么是成瘾' }, { id: 31, name: '什么是睡眠障碍' }, { id: 29, name: '什么是焦虑' }, { id: 34, name: '什么是注意力缺陷/多动障碍' }, { id: 28, name: '什么是孤独谱系障碍' }, { id: 30, name: '什么是认知功能障碍' }, { id: 33, name: '什么是抑郁症' }, { id: 35, name: '双相情感障碍' }, { id: 24, name: '进食障碍' }, { id: 26, name: '慢性疼痛' }] },

                        { id: 2, name: '个性', show: false, list1: [{ id: 18, name: '被动攻击' }, { id: 27, name: '什么是个性' }, { id: 20, name: '害羞' }] },

                        { id: 3, name: '关系', show: false, list1: [{ id: 38, name: '性欲低下' }, { id: 36, name: '为什么关系很重要' }, { id: 37, name: '性的基础' }, { id: 25, name: '了解儿童发展' }, { id: 22, name: '家长的角色' }] },

                        { id: 4, name: '个人成长', show: false, list1: [{ id: 19, name: '赋予动机' }, { id: 21, name: '积极心理学' }, { id: 32, name: '幸福' }, { id: 23, name: '戒烟' }] },]
                },
                { id: 3, title: '新闻', path: '/news' },

                // { id: 1, title: '首页', path: '/index' },
                // { id: 2, title: '曼扉心理', path: '/dcmf' },
                // { id: 3, title: '新闻', path: '/news' },
                // { id: 4, title: '心理科普', path: '/universal' },
                // { id: 5, title: '心理服务', path: '/service/index' },
                // { id: 6, title: '企业心理服务', path: '/enterprise/index' },
            ],
            universalList: [
                { id: 1, name: '心理健康', list1: [{ id: 47, name: '什么是成瘾' }, { id: 31, name: '什么是睡眠障碍' }, { id: 29, name: '什么是焦虑' }, { id: 34, name: '什么是注意力缺陷/多动障碍' }, { id: 28, name: '什么是孤独谱系障碍' }], list2: [{ id: 30, name: '什么是认知功能障碍' }, { id: 33, name: '什么是抑郁症' }, { id: 35, name: '双相情感障碍' }, { id: 24, name: '进食障碍' }, { id: 26, name: '慢性疼痛' }] },

                { id: 2, name: '个性', list1: [{ id: 18, name: '被动攻击' }, { id: 27, name: '什么是个性' }, { id: 20, name: '害羞' }] },

                { id: 3, name: '关系', list1: [{ id: 38, name: '性欲低下' }, { id: 36, name: '为什么关系很重要' }, { id: 37, name: '性的基础' }, { id: 25, name: '了解儿童发展' }, { id: 22, name: '家长的角色' }] },

                { id: 4, name: '个人成长', list1: [{ id: 19, name: '赋予动机' }, { id: 21, name: '积极心理学' }, { id: 32, name: '幸福' }, { id: 23, name: '戒烟' }] },
            ], // 心理科普
            serviceList: [
                {
                    id: 11, title: '心理测试', path: '/index', children: [
                        // { id: 10, title: '心理测试', path: '/index' },
                        { id: 1, title: '成瘾中心', path: '/dcmf' },
                        { id: 2, title: '睡眠中心', path: '/news' },
                    ]
                },
                {
                    id: 12, title: '2', children: [
                        { id: 3, title: '关系中心—亲密关系单元', path: '/universal' },
                        { id: 4, title: '关系中心—亲子沟通中心', path: '/service/index' },
                        { id: 5, title: '关系中心—人际关系中心', path: '/enterprise/index' },
                    ]
                },
                {
                    id: 13, title: '3', children: [
                        { id: 6, title: '个人成长中心', path: '/news' },
                        { id: 7, title: '学习困难中心', path: '/news' },
                    ]
                },
                {
                    id: 4, title: '4', children: [
                        { id: 8, title: '情绪障碍中心', path: '/news' },
                        { id: 9, title: '认知障碍中心', path: '/news' },
                    ]
                },


            ], // 心理服务
            serviceShow: false, // 控制心理服务二级菜单是否显示
            universalShow: false, // 控制心理科普二级菜单是否显示
            isPc: true,// 手机端还是移动端
        };
    },
    methods: {
        handleSelect(key) {
            // console.log(key, keyPath);
            this.$router.push(key)
        },
        openDraw() {
            this.$refs.child.open();
        },
        inUniversal() {
            // console.log('移入');
            this.universalShow = true;
            this.serviceShow = false;
        },
        leave() {
            // console.log('移出')
            this.universalShow = false;
            this.serviceShow = false;
        },
        inUniversal2() {
            // console.log('移入');
            this.serviceShow = true;
            this.universalShow = false;
        },
        // 跳转到文章详情
        toArticleDetail(val, val2) {
            // console.log('22222')
            if (this.$route.path !== '/universal/detail') {
                this.$router.push({
                    path: '/universal/detail',
                    query: {
                        id: val,
                        active: val2
                    }
                })
            } else {
                this.$router.push({
                    path: '/universal/detail',
                    query: {
                        id: val,
                        active: val2
                    }
                })
                location.reload();
            }

            // location.reload();
        },
        // 跳转到中心
        toCentre(val) {
            console.log(val)
            if (val === 10) {
                this.$router.push('/service/gauge')
            } else {

                if (this.$route.path === '/service/centre') {
                    sessionStorage.setItem('centreId', val);
                    location.reload();
                    // this.$forceUpdate();

                } else {
                    sessionStorage.setItem('centreId', val)
                    this.$router.push('/service/centre');

                }
            }

        }
    },
    computed: {
        path() {
            return this.$route.path;
        },
        activeName() {
            if (this.$route.path.indexOf('service') !== -1) {
                return '心理服务'
            } else if (this.$route.path.indexOf('enterprise') !== -1) {
                return '企业心理服务'
            } else if (this.$route.path.indexOf('test') !== -1) {
                return '心理测评'
            } else if (this.$route.path.indexOf('/article') !== -1) {
                return '心理科普'
            } else {
                let activeItm = this.menuList.filter(item => {
                    if (this.$route.path.indexOf(item.path) !== -1) {
                        return item
                    }

                })
                return activeItm[0].title
            }

            // console.log(this.$route.path)
            // console.log(activeItm)
            // if (activeItm.length > 1) {
            //     console.log(2)
            //     return activeItm[1].title
            // } else {
            //     console.log(1)
            //     return activeItm[0].title
            // }
        }
        // console.log(this.$route.path)

    },
    created() {
        // if (window.screen.width < 800) {
        //     // 当前设备是移动设备
        //     console.log('当前设备是移动设备')
        //     this.isPc = false;
        // } else {
        //     //   console.log('电脑')
        //     this.isPc = true;
        // }
        // var screen_width = window.screen.width;
        // var screen_height = window.screen.height;

        // //根据屏幕分辨率判断是否是手机
        // if (screen_width > 325 && screen_height < 750) {
        //     this.isPc = false;
        // } else {
        //     console.log('电脑')
        //     this.isPc = true;
        // }
        // var userAgentInfo = navigator.userAgent;

        // var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

        // // var mobile_flag = false;

        // //根据userAgent判断是否是手机
        // for (var v = 0; v < mobileAgents.length; v++) {
        //     if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
        //         this.isPc = false;
        //         break;
        //     } else {
        //         this.isPc = true;
        //     }
        // }
        // console.log(this.isPc)
        this.isPc = sessionStorage.getItem('isPc');
        // console.log(this.isPc)
    }

}
</script>

<style lang="scss" scoped>
.head {

    height: 80px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

    .pc.main{
        margin: 0 auto;
        height: 100%;
        // width: 100%;
        // width: 1120px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: space-between;

        .secondMenu {
            padding-top: 22px;
            position: absolute;
            background: white;
            top: 81px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            height: 300px;

            ul {
                li {
                    &:hover {
                        span {
                            cursor: pointer;
                            text-decoration: underline;
                            color: #D1B458;
                        }
                    }
                }
            }

            .item {
                width: 220px;
                border-right: 1px solid #DDDDDD;
                padding-left: 30px;
                text-align: left;
                box-sizing: border-box;

                &:first-child {
                    box-sizing: border-box;
                    width: 440px;

                    ul {
                        width: 220px;
                    }
                }

                // text-align: left;
                // padding-right: 35px;
                .title {
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--headFont-color);

                }

                .list {
                    display: flex;

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            // line-height: 23px;
                            margin-top: 23px;
                            font-size: 15px;
                            font-weight: 600;
                            color: #888888;


                        }

                        // width: 220px;

                    }
                }


            }
        }

        .secondMenu.secondMenu2 {
            // display: none;
            padding-top: 0;

            .item {
                width: 270px;


            }
        }

        .logo {

            // line-height: 80px;
            padding: 24px 0px;

            img {
                // width: 214px;
                height: 32px;
            }
        }

        .menu {
            // background: red;
            margin-left: 20px;
            display: flex;
            align-items: center;
            box-sizing: border-box;

            // width: 720px;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                height: 48px;
                display: flex;
                align-items: center;
                // flex-wrap: nowrap;

                // justify-content: center;
                li {
                    // flex: 1;
                    // width: 120px;
                    // height: 48px;
                    // line-height: 48px;
                    padding: 10px 20px;
                    text-align: center;
                    white-space: nowrap;
                    // display: inline-block;
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--headFont-color);
                    cursor: pointer;

                    .el-icon-arrow-down {
                        font-weight: 600;
                    }

                    &:first-child {
                        margin-left: 0px;
                    }

                    &:hover {
                        background: var(--custom-color);

                    }
                }

                .active {
                    background: var(--custom-color);
                }
            }
        }
    }

    .main.phone {
        display: none;
        // width: 90%;
        height: 100%;
        position: relative;
        align-items: center;

        // padding: 20px 0;
        .el-icon-s-fold {
            position: absolute;
            right: 0;
            font-size: 46px;
        }

        span {
            display: inline-block;
            width: 100%;
            text-align: center;
            // font-size: 28px;
            font-weight: 600;
            color: #221508;
            line-height: 80px;
        }

        // justify-content: space-between;
        img {
            height: 38px;
            position: absolute;
            left: 0;
        }


    }
}
@media screen and (max-width: 1000px) {
    .head {
        .main.pc {
            display: none;
        }
    }

    .head .main.phone {
        display: flex;
        width: 90%;

        .el-icon-s-fold {
            font-size: 24px !important;
        }

        span {
            font-size: 18px !important;
        }

        img {
            height: 26px !important;
        }
    }
}
</style>