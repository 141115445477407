<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import { deviceEnquire, DEVICE_TYPE } from './utils/device';
export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      nowPath: ''
    }
  },
  methods: {

  },
  mounted() {
    this.nowPath = this.$route.path
    console.log(this.$route.path)
  },

  created() {
    console.log(window.screen.width)
    // let urlData = (this.$route.path).split('/')
    // let val = urlData[urlData.length-1]
    // console.log(val)
    // if((this.$route.path).indexOf('article/') !== -1) {
    //   this.$router.push({
    //     path: '/linkDetail',
    //     query: {
    //       detailId: val
    //     }
    //   })
    // }
  }
  // mounted() {
  //   deviceEnquire(deviceType => {
  //     switch (deviceType) {
  //     case DEVICE_TYPE.DESKTOP:
  //       // $store.commit('TOGGLE_DEVICE', 'desktop');
  //       // $store.dispatch('setSidebar', true);
  //       break;
  //     case DEVICE_TYPE.TABLET:
  //       // $store.commit('TOGGLE_DEVICE', 'tablet');
  //       // $store.dispatch('setSidebar', false);
  //       break;
  //     case DEVICE_TYPE.MOBILE:
  //     default:
  //       // $store.commit('TOGGLE_DEVICE', 'mobile');
  //       // $store.dispatch('setSidebar', true);
  //       break;
  //     }
  //   });
  // }
}
</script>

<style lang="scss">
@import './assets/css/style.scss';

#app {
  font-family: PingFangSC-Regular, PingFang SC;
}

body {
  margin: 0;
  padding: 0;

  // height: 100%;
  p {
    margin: 0;
    padding: 0;
  }
}

.main {
  width: 1100px;
  // width: 59%;
  margin: 0 auto;
  /* background: black; */
  display: flex;

  .p1 {
    font-size: 20px;
  }

  .p2 {
    font-size: 15px;
  }

  .p3 {
    font-size: 14px;
  }

  .p4 {
    font-size: 16px;
  }

  .span1 {
    font-size: 15px;
  }

  .span2 {
    font-size: 14px;
  }

  .span3 {
    font-size: 16px;
  }

  .bottomStyle {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: var(--custom-color);
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.3);

  }

  .titleContent {
    width: 100%;
    margin-top: 36px;
    text-align: center;
    margin-bottom: 15px;

    p {
      // font-size: 16px;
      font-weight: 600;
      color: var(--titleFont-color);
      line-height: 22px;
    }


    span {
      font-size: 14px;
      color: var(--spanFont-color);
      line-height: 20px;
      text-transform: uppercase;
    }
  }
}

// .phoneStyle.main{
//   width: 90%;
//   margin: 0 auto;
//     /* background: black; */
//   display: flex;
// }
.v-modal {
  z-index: 2 !important;
}

.block {
  text-align: right;
}


@media screen and (min-width:600px) and (max-width:1200px) {
  .main {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .main {
    width: 90%;
    margin: 0 auto;
    /* background: black; */
    display: flex;
  }
}</style>
