<template>
  <!-- 底部 -->
  <div :class="isMargin ? 'index noMargin' : ' index'">
    <!-- 底部 -->
    <div class="main pc" >
      <div class="top">
        <div class="about">
          <div class="container">
            <p>关于我们</p>
            <span>曼扉心理是一家专注于中高端医学的心理健康服务品牌，由知名精神科专家和心理学教授联合创立。</span>
          </div>
        </div>
        <div class="code">
          <div class="box2"></div>
          <div class="box1"></div>
          <div class="container">
            <p>关注我们</p>
            <img src="../../assets/images/foot/code.png" alt="">
          </div>
        </div>
        <div class="contact">
          <div class="container">
            <p>联系我们</p>
            <span>成都锦江曼扉心理诊所有限公司</span>
            <span>成都市锦江区庆云南街115号</span>
            <span>028-87057733/19302852468</span>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p>
          <img src="../../assets/images/foot/power.png" alt="">
          <span><a href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2022030036号-1</a> </span>
        </p>
        <p> Copyright 2020-2023 成都锦江曼扉心理诊所有限公司</p>
      </div>
    </div>
    <div class="main phone">
      <div>
        <div class="top">
          <div class="left">
            <p>成都锦江曼扉心理诊所有限公司</p>
            <p>成都市锦江区庆云南街115号</p>
            <p>028-87057733/19302852468</p>
          </div>
          <div class="right">
            <img src="../../assets/images/foot/code.png" alt="">
          </div>
        </div>
        <div class="bottom">
          <p>曼扉心理是一家专注于中高端医学的心理健康服务品牌，由知名精神科专家和心理学教授联合创立。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    isMargin() {
      if (this.$route.path.indexOf('evaluation') === -1) {
        return false
      } else {
        return true
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  // height: 197px;
  background: var(--custom-color);
  margin-top: 70px;

  .main.pc {
    height: 100%;
    display: block;

    .top {
      display: flex;



      p {
        font-size: 16px;
        color: #221508;
        margin-bottom: 18px;
      }

      span {
        font-size: 14px;
        color: #777777;
        display: inline-block;

        margin-bottom: 16px;

      }

      .container {
        width: 197px;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
      }



      .about,
      .contact {
        box-sizing: border-box;
        width: 386px;
        // width: 100%;
        // flex: 1;
        // height: 100%;
        background: var(--bottom-color);
        padding: 32px 0;
        // box-sizing: border-box;
      }

      .contact {
        .container {
          text-align: left;

          p {
            text-align: center;
          }

          span {
            // letter-spacing: 1px;
          }
        }
      }

      .code {
        width: 366px;
        height: 100%;
        box-sizing: border-box;
        padding: 32px 0;
        position: relative;

        .box1 {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 0;
          height: 0;
          border-color: var(--bottom-color) transparent transparent transparent;
          border-style: solid;
          border-width: 197px 0 0 12px;
        }

        .box2 {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 0;
          /*1.设置宽高为0*/

          height: 0;

          /*2.除右边都变透明色，实边*/
          border-color: var(--bottom-color) transparent transparent transparent;
          border-style: solid;
          border-width: 197px 12px 0 0;
        }


        img {
          // margin: 0 auto;
          height: 92px;
          width: 92px;
        }

      }
    }

    .bottom {
      border-top: 1px solid #FFFFFF;
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 25px;
      p {
        margin-top: 12px;
        text-align: center;
        font-size: 14px;
        color: #221508;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 20px;
        margin-right: 5px;
      }
      a{
        color: #221508;
        text-decoration: none;
      }
    }

  }

  .main.phone {
    // width: 90%;
    display: none;
    background: var(--custom-color);
    // display: block;
    padding: 15px 10px;
    width: 100%;
    box-sizing: border-box;

    p {
      font-size: 26px;
      color: #777777;
      margin-top: 12px;
    }

    .top {
      padding-bottom: 15px;
      display: flex;
      
      justify-content: space-around;
      border-bottom: 1px solid #221508;

      .right {
        // margin-left: 30px;
        img {
          height: 110px;
        }
      }
    }

    .bottom {

      // padding:0px 10px;
      p {
        margin-top: 15px;
        // margin-bottom: 15px;
        // line-height: 25px;
      }
    }
  }
}

.noMargin {
  margin-top: 0px;
}
@media screen and (max-width: 800PX) {
  .index  .main.pc {
    display: none;
  }
  .index  .main.phone{
    display: block;
    p{
    font-size: 14px !important;
  } 
  }
  
}
// @media screen and (max-width: 830PX) {
//   .main.pc{
//         display: none;
//     }
// }
// @media screen and (min-width: 831PX) {
//   .main.phone {
//         display: none;
//     }
// }
</style>