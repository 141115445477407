import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPhone: false, // 手机还是电脑
    gaugeListInfo: {
      planId: 1,
      isMerge: 0
    },
    imgUrl: 'https://webadmin.dasmindfit.com',
    bannerUrl: 'https://webadmin.dasmindfit.com/attachment/20230225/',
    requestApi: 'https://webadmin.dasmindfit.com/api',
    newsTypeId: 1,// 新闻类型的id
    healthTypeId: 3, // 心理健康类型的id
    personalityTypeId: 4, // 个性类型的id
    relationshipTypeId: 5, // 关系类型的id
    growTypeId: 6, // 个人成长类型的id

    articleAllType: {
      addictionType: 11, //成瘾中心
      sleepType: 12, //睡眠中心
      intimacyType: 13, //关系中心—亲密关系单元
      communicationType: 14,//关系中心—亲密关系单元
      interpersonalRelationshipType: 15,//关系中心—亲密关系单元
      growType: 16,//个人成长中心
      learnType: 17,//学习困难中心
      emotionType: 18,//情绪障碍中心
      cognitionType: 19,//认知障碍中心
      
    },
    bannerImgs: {
      index: '8fc2e25b0f7a42438f13be5973d2bbec.jpg',
      news: '6fab2c65b25243c9a00942091a198217.jpg',
      dcmf: 'c7dda64456fd4711af273dfa70e92826.jpg',
      xlkp: '79b7ee4e192b49e9bf28b4f973caeee9.jpg',
      xlfw: 'd71ff85f35d649548e8e165fe07fbba9.jpg',
      qyxlfw: 'bb1fd94320034ecfae5bf5b13f6bdfc8.jpg',
    }
  },
  mutations: {
    changeIsPhone(state,val) {
      state.isPhone = val
      // console.log(state.isPhone)
    },
  },
  actions: {
  },
  modules: {
  }
})
