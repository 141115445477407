<template>
    <div class="menu">
        <el-drawer :visible.sync="drawer" :with-header="false" size="50%">
            <ul>
                <li :class="item.path === activePath ? 'activeLi' : ''" v-for="item in menuList" :key="item.id">
                    <span @click="checkPath(item)">
                        {{ item.title }}
                        <i class="el-icon-arrow-down" v-if="item.id === 4 || item.id === 5"></i>
                    </span>
                    <ul v-if="item.id === 4 && item.show === true" class="secondUl">
                        <li v-for="ele in item.children" :key="ele.id">
                            <span @click="openSecond(ele)">{{ ele.name }}<i class="el-icon-arrow-down"></i></span>
                            <ul class="thirdUl" v-if="ele.show">
                                <li v-for="ele2 in ele.list1" :key="ele2.id" @click="checkPath2(ele2, ele)">
                                    <span>{{ ele2.name }}</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-if="item.id === 5 && item.show === true" class="secondUl">
                        <li v-for="ele2 in item.children" :key="ele2.id" @click="checkPath3(ele2)">
                            <span>{{ ele2.title }}</span>
                        </li>

                    </ul>
                </li>
            </ul>
        </el-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            menuList: [
                { id: 1, title: '首页', path: '/index' },
                {
                    id: 5, title: '心理服务', path: '/service/index', show: false, children: [

                        // { id: 10, title: '心理测试', path: '/index' },
                        { id: 1, title: '成瘾中心', path: '/dcmf' },
                        { id: 2, title: '睡眠中心', path: '/news' },
                        { id: 3, title: '关系中心—亲密关系单元', path: '/universal' },
                        { id: 4, title: '关系中心—亲子沟通中心', path: '/service/index' },
                        { id: 5, title: '关系中心—人际关系中心', path: '/enterprise/index' },
                        { id: 6, title: '个人成长中心', path: '/news' },
                        { id: 7, title: '学习困难中心', path: '/news' },
                        { id: 8, title: '情绪障碍中心', path: '/news' },
                        { id: 9, title: '认知障碍中心', path: '/news' },
                    ]
                },
                { id: 7, title: '心理测评', path: '/test/gauge' },
                { id: 6, title: '企业心理服务', path: '/enterprise/index' },
                { id: 2, title: '了解我们', path: '/dcmf' },

                {
                    id: 4, title: '心理科普', path: '/universal', show: false, children: [
                        { id: 1, name: '心理健康', show: false, list1: [{ id: 47, name: '什么是成瘾' }, { id: 31, name: '什么是睡眠障碍' }, { id: 29, name: '什么是焦虑' }, { id: 34, name: '什么是注意力缺陷/多动障碍' }, { id: 28, name: '什么是孤独谱系障碍' }, { id: 30, name: '什么是认知功能障碍' }, { id: 33, name: '什么是抑郁症' }, { id: 35, name: '双相情感障碍' }, { id: 24, name: '进食障碍' }, { id: 26, name: '慢性疼痛' }] },

                        { id: 2, name: '个性', show: false, list1: [{ id: 18, name: '被动攻击' }, { id: 27, name: '什么是个性' }, { id: 20, name: '害羞' }] },

                        { id: 3, name: '关系', show: false, list1: [{ id: 38, name: '性欲低下' }, { id: 36, name: '为什么关系很重要' }, { id: 37, name: '性的基础' }, { id: 25, name: '了解儿童发展' }, { id: 22, name: '家长的角色' }] },

                        { id: 4, name: '个人成长', show: false, list1: [{ id: 19, name: '赋予动机' }, { id: 21, name: '积极心理学' }, { id: 32, name: '幸福' }, { id: 23, name: '戒烟' }] },]
                },
                { id: 3, title: '新闻', path: '/news' },


            ],
        }
    },
    methods: {
        open() {
            this.drawer = true;
        },
        checkPath(val) {

            if (val.id === 4 || val.id === 5) {

                val.show = !val.show;
                this.$router.push(`${val.path}`)
            } else {
                this.$router.push(`${val.path}`)
                this.drawer = false;
            }
            // e.preventDefault();
            // this.$router.push(`${val.path}`)
            // this.drawer = false;

        },
        openSecond(val) {
            val.show = !val.show;
        },
        checkPath2(val, val2, e) {
            // console.log(val)
            // e.preventDefault();

            if (this.$route.path !== '/universal/detail') {
                this.$router.push({
                    path: '/universal/detail',
                    query: {
                        id: val.id,
                        active: val2.id
                    }
                })
            } else {
                this.$router.push({
                    path: '/universal/detail',
                    query: {
                        id: val.id,
                        active: val2.id
                    }
                })
                location.reload();
            }
            this.drawer = false;
            // this.$router.push({
            //     path: `/universal`,
            //     query: {
            //         active: val.id
            //     }
            // })
            // if(this.$route.path === '/universal') {

            // } else {
            //     this.$router.push({
            //     path: `/universal`,
            //     query: {
            //         active: val.id
            //     }
            // })
            // }
            // location.reload();
            // this.drawer = false;
        },
        checkPath3(val) {
            // console.log(val)
            sessionStorage.setItem('centreId', val.id)
            if (val.id === 10) {
                this.$router.push('/service/gauge')
            } else {
                if (this.$route.path === '/service/centre') {
                    sessionStorage.setItem('centreId', val.id);
                    location.reload();
                    // this.$forceUpdate();

                } else {
                    sessionStorage.setItem('centreId', val.id)
                    this.$router.push('/service/centre');

                }
                // location.reload();
            }
            this.drawer = false;
        },
    },
    computed: {
        activePath() {
            // console.log((this.$route.path).slice(0,5))
            // return (this.$route.path).slice(0,5);
            if (this.$route.path.indexOf('service') !== -1) {
                return '/service/index'
            } else if (this.$route.path.indexOf('enterprise') !== -1) {
                return '/enterprise/index'
            } else if (this.$route.path.indexOf('universal') !== -1 || this.$route.path.indexOf('/article') !== -1) {
                return '/universal'
            } else if (this.$route.path.indexOf('news') !== -1) {
                return '/news'
            } else if (this.$route.path.indexOf('dcmf') !== -1) {
                return '/dcmf'
            } else if (this.$route.path.indexOf('test') !== -1) {
                return '/test/gauge'
            }
            else {
                return '/index'
            }
            //  else {
            //     let activeItm = this.menuList.filter(item => {
            //         if (this.$route.path.indexOf(item.path) !== -1) {
            //             return item
            //         }

            //     })
            //     return activeItm[0].path
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    width: 100%;

    ul {
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            text-align: center;
            list-style: none;
            line-height: 80px;
            // background: var(--custom-color);
            border-bottom: 1px solid #EEE;

            .secondUl {
                li {

                    // text-align: left;
                    // padding-left: 20px;
                    span {
                        font-weight: 500;
                        // font-size: 18px;
                    }

                    .thirdUl {
                        span {
                            font-weight: 500;
                            font-size: 14px;
                        }

                        .thirdUl {
                            li {
                                span {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            span {
                font-weight: 600;
                font-size: 20px;
            }
        }

        .activeLi {
            background: var(--custom-color);

            .secondUl {
                background: white;
            }
        }

    }
}

@media screen and (max-width: 600PX) {
    .menu {
        ul {
            li {

                span {
                    font-size: 16px;
                }

                .secondUl {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>