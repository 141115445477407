import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// import 'lib-flexible/flexible.js'
//全局引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
// import { VueJsonp } from 'vue-jsonp'
// Vue.use(VueJsonp)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
