import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
      // 首页
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index/Index.vue')
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../views/news/Index.vue')
      },
      {
        path: 'newsDetail',
        name: 'NewsDetail',
        component: () => import('../views/news/Detail.vue')
      },
      {
        path: '/dcmf',
        name: 'Dcmf',
        component: () => import('../views/dcmf/Index.vue')
      },

      // 企业心理服务
      {
        path: '/enterprise',
        name: 'Enterprise',
        component: () => import('../views/enterprise/Index.vue'),
        children: [{
            path: 'index',
            name: 'Index',
            component: () => import('../views/enterprise/Home.vue'),
          },
          {
            path: 'programme',
            name: 'programme',
            component: () => import('../views/enterprise/Programme.vue'),
          },
          {
            path: 'assessment',
            name: 'Assessment',
            component: () => import('../views/enterprise/Assessment.vue'),
          },
          {
            path: 'lecture',
            name: 'Lecture',
            component: () => import('../views/enterprise/Lecture.vue'),
          },
        ]
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('../views/Evaluation/Index.vue'),
        children: [
        {
          path: 'gauge',
          name: 'gauge',
          component: () => import('../views/service/gauge/Index.vue')
        },
      ]
        },
      {
        path: '/service',
        name: 'service',
        component: () => import('../views/service/Index.vue'),
        children: [{
          path: 'index',
          name: 'serviceIndex',
          component: () => import('../views/service/Home.vue')
        },
        
        {
          path: 'centre',
          name: 'Centre',
          component: () => import('../views/service/centre/Index.vue')
        },
        
      ]
      },
      // 量表介绍
      {
        path: '/test/evaluation',
        name: 'Evaluation',
        component: () => import('../views/service/gauge/Evaluation.vue'),
        children: [
          // 介绍
          {path: 'introduce',
          name: 'Introduce',
          component: () => import('../views/service/gauge/Introduce.vue')},
           // 答题
          {path: 'answer',
          name: 'Answer',
          component: () => import('../views/service/gauge/Answer.vue')},
          //报告页面
          {path: 'report',
          name: 'Report',
          component: () => import('../views/service/gauge/Report.vue')},
        ]
      },
      {
        path: '/universal',
        name: 'Universal',
        component: () => import('../views/universal/Index.vue'),
        // children: [{
        //     path: 'health',
        //     name: 'Health',
        //     component: () => import('../views/universal/health/Index.vue'),
        //   },
        //   {
        //     path: 'relationship',
        //     name: 'Relationship',
        //     component: () => import('../views/universal/relationship/Index.vue'),
        //   },
        //   {
        //     path: 'personality',
        //     name: 'Personality',
        //     component: () => import('../views/universal/personality/Index.vue'),
        //   },
        //   {
        //     path: 'grow',
        //     name: 'Grow',
        //     component: () => import('../views/universal/grow/Index.vue'),
        //   },
          
        // ]
      },
      {
        path: '/article/:Id',
        name: 'LinkDetail',
        component: () => import('../views/universal/LinkDetail.vue')
      },
      {
        path: '/universal/detail',
        name: 'Detail',
        component: () => import('../views/universal/Detail.vue'),
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
    // return 期望滚动到哪个的位置
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
           return savedPosition
      } else {
       // vue2.0  x  y  控制
       // vue3.0  left  top 控制
      return { x: 0, y: 0 }
   }
  }
  // mode:'history'
})

export default router